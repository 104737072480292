<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <b-img
        v-if="item.isMarketplace"
        :src="
          require('@/assets/images/marketplace/' +
            item.title.toLowerCase() +
            '.png')
        "
        :alt="item.title.toLowerCase()"
        style="width: 16px; height: 16px"
        class="mr-1"
      />
      <div v-else>
        <font-awesome-icon
          v-if="item.fwicon"
          :icon="[item.iconPrefix || 'fas', item.fwicon]"
          :class="isActive ? '' : item.iconClasses || 'mr-1'"
          :style="item.iconStyle || ''"
        />
        <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
      </div>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { BLink, BBadge, BImg } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';

export default {
  components: {
    BLink,
    BBadge,
    BImg,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item,
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>

<style lang="scss">
.text-white {
  color: white;
}
</style>
