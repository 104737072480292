<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <b-img
        v-if="item.isMarketplace"
        :src="
          require('@/assets/images/marketplace/' +
            item.title.toLowerCase() +
            '.png')
        "
        :alt="item.title.toLowerCase()"
        style="width: 24px; height: 24px"
      />
      <div v-else>
        <font-awesome-icon
          v-if="item.fwicon"
          :icon="[item.iconPrefix || 'fas', item.fwicon]"
          :class="isOpen ? '' : item.iconClasses || 'mr-1'"
        />
        <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
      </div>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse, BImg } from 'bootstrap-vue';
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { useUtils as useAclUtils } from '@core/libs/acl';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup';
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup';

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
    BImg,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isOpen, isActive, updateGroupOpen, updateIsActive } =
      useVerticalNavMenuGroup(props.item);

    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuGroup } = useAclUtils();

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    };
  },
};
</script>

<style lang="scss">
.color-accecpted {
  color: #28a745;
}

.color-rejected {
  color: #dc3545;
}

.color-ready-to-ship {
  color: #ffc107;
}

.color-shipping {
  color: #007bff;
}

.color-in-shipping {
  color: #17a2b8;
}

.color-new {
  color: #e038c9;
}

.color-master-product {
  color: #ff8c00;
}

.color-pink {
  color: #ff1493;
}
</style>
