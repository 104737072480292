<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />

      <div class="d-flex justify-content-start ml-1">
        <!-- <b-link class="text-primary" href="mailto:bantuan@kanaldagang.com"
          >Hubungi kami</b-link
        >
        jika anda membutuhkan bantuan -->

        <b-badge variant="light-primary" class="mr-1">
          <b-img :src="require('@/assets/images/marketplace/tokopedia.png')" width="16" />
          : {{ marketplaceData.tokopedia }}
        </b-badge>
        <b-badge variant="light-primary" class="mr-1">
          <b-img :src="require('@/assets/images/marketplace/shopee.png')" width="16" />
          : {{ marketplaceData.shopee }}
        </b-badge>
        <b-badge variant="light-primary" class="mr-1">
          <b-img :src="require('@/assets/images/marketplace/lazada.png')" width="16" />
          : {{ marketplaceData.lazada }}
        </b-badge>
        <b-badge variant="light-primary" class="mr-1">
          <b-img :src="require('@/assets/images/marketplace/tiktok.png')" width="16" />
          : {{ marketplaceData.tiktok }}
        </b-badge>
        <b-badge variant="light-primary" class="mr-1">
          <b-img :src="require('@/assets/images/marketplace/blibli.png')" width="16" />
          : {{ marketplaceData.blibli }}
        </b-badge>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullname }}
            </p>
            <span class="user-status">{{ email }}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="avatar" class="badge-minimal"
            badge-variant="success" />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'account-setting' }"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Pengaturan Akun</span>
        </b-dropdown-item> -->

        <b-dropdown-item v-b-modal.modal-change-password link-class="d-flex align-items-center">
          <font-awesome-icon :icon="['fas', 'key']" class="mr-50" />
          <span>Ubah Kata Sandi</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Keluar</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- modal change password -->
    <b-modal id="modal-change-password" title="Ubah Kata Sandi" size="md" centered no-close-on-backdrop no-close-on-esc>
      <b-form-group label-cols="4" label-cols-lg="4" label="Kata Sandi Lama" label-for="input-password-old"
        label-size="sm">
        <b-form-input id="input-password-old" v-model="formdata.oldPassword" autocomplete="off" size="sm"
          type="password" placeholder="Masukkan Kata Sandi Lama"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="4" label-cols-lg="4" label="Kata Sandi Baru" label-for="input-password" label-size="sm">
        <b-form-input id="input-password" v-model="formdata.password" autocomplete="off" size="sm" type="password"
          placeholder="Masukkan Kata Sandi Baru"></b-form-input>
      </b-form-group>
      <b-form-group label-cols="4" label-cols-lg="4" label="Ulangi Kata Sandi Baru" label-for="input-retype-password"
        label-size="sm">
        <b-form-input id="input-retype-password" v-model="formdata.retypePassword" autocomplete="off" size="sm"
          type="password" placeholder="Ulangi Kata Sandi Baru"></b-form-input>
      </b-form-group>

      <span v-if="formdata.password !== formdata.retypePassword" class="text-danger d-block mb-1">kata sandi baru dan
        ulangi
        kata sandi baru harus sama</span>
      <template #modal-footer>
        <b-button variant="primary" class="mr-1" size="sm" :disabled="formdata.password === '' ||
          formdata.retypePassword === '' ||
          formdata.password !== formdata.retypePassword ||
          formdata.oldPassword === ''
          " @click="changePassword">
          <Feather-icon icon="SaveIcon" size="16" class="mr-50" /> Ganti Kata
          Sandi
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BDropdownDivider,
  BModal,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
  BImg,
} from 'bootstrap-vue';
import decode from 'jsonwebtoken/decode';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import useJwt from '@/auth/jwt/useJwt';
import initialAbility from '@/libs/acl/ability';
import axios from '@axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ToastificationContent,
    DarkToggler,
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    BImg,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      fullname: useJwt.getToken() ? decode(useJwt.getToken()).data.name : null,
      avatar: `https://cdn.statically.io/avatar/${useJwt.getToken() ? decode(useJwt.getToken()).data.name : 'user'
        }`,
      email: useJwt.getToken() ? decode(useJwt.getToken()).data.email : null,
      formdata: {
        oldPassword: '',
        password: '',
        retypePassword: '',
      },
      marketplaceData: {
        tokopedia: 0,
        shopee: 0,
        lazada: 0,
        tiktok: 0,
        blibli: 0
      }
    };
  },
  mounted() {
    this.fetchMarketplaceData();
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      this.$ability.update(initialAbility);
      window.location.href = '/login';
    },
    async changePassword() {
      const urlWithID = `/users/change-password/${decode(useJwt.getToken()).data.id
        }`;

      const response = await axios.put(urlWithID, {
        passwordNew: this.formdata.password,
        passwordOld: this.formdata.oldPassword,
      });

      if (response && response.data && response.data.status === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Password changed successfully',
          },
        });

        this.$bvModal.hide('modal-change-password');
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'InfoIcon',
            text: 'Password changed failed',
          },
        });
      }
    },
    async fetchMarketplaceData() {
      try {
        await this.$store.dispatch('marketplace/getAllMarketplace', { page: 1 });

        const marketplaceData = this.$store.state.marketplace.all.data;
        for (const marketplace of marketplaceData) {
          this.marketplaceData[marketplace.marketplaceName]++;
        }
      } catch (error) {
        console.error(`Error fetching data:`, error);
      }
    }
  },
});
</script>
