export default [
  {
    title: 'Beranda',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'all',
  },
  {
    header: 'Kelola Pesanan',
    resource: 'order',
    action: 'all',
  },
  {
    title: 'Pesanan',
    fwicon: 'shopping-cart',
    iconClasses: 'text-primary',
    route: 'orders',
    resource: 'order',
    action: 'all',
    children: [
      {
        title: 'Baru',
        resource: 'order',
        route: 'OrdersNew',
        action: 'all',
        fwicon: 'shopping-cart',
        iconClasses: 'color-new',
        iconPrefix: 'fas',
      },
      {
        title: 'Diterima',
        route: 'OrdersAccepted',
        resource: 'order',
        action: 'all',
        fwicon: 'box',
        iconClasses: 'color-accecpted',
        iconPrefix: 'fas',
      },
      {
        title: 'Siap Kirim',
        route: 'OrdersReadyToShip',
        resource: 'order',
        action: 'all',
        fwicon: 'boxes-packing',
        iconClasses: 'color-ready-to-ship',
      },
      {
        title: 'Dalam Pengiriman',
        route: 'OrdersShipping',
        resource: 'order',
        action: 'all',
        fwicon: 'check-to-slot',
        iconClasses: 'color-in-shipping',
      },
      {
        title: 'Selesai',
        route: 'OrdersFinished',
        resource: 'order',
        action: 'all',
        fwicon: 'check-to-slot',
        iconClasses: 'text-success',
      },
      {
        title: 'Dibatalkan',
        route: 'OrdersCancelled',
        resource: 'order',
        action: 'all',
        fwicon: 'ban',
        iconClasses: 'text-danger',
      },
    ],
  },
  {
    header: 'Kelola Produk',
    resource: 'product',
    action: 'all',
  },
  {
    title: 'Produk Utama',
    icon: 'BoxIcon',
    resource: 'product',
    action: 'all',
    route: 'productsMaster',
    fwicon: 'boxes-stacked',
    iconClasses: 'color-pink',
    iconPrefix: 'fas',
  },
  {
    title: 'Toko Saya',
    route: 'productsMarketplace',
    resource: 'product',
    action: 'all',
    fwicon: 'shop',
    iconClasses: 'text-warning',
    iconPrefix: 'fas',
    children: [
      {
        title: 'Tokopedia',
        route: 'ProductsTokopedia',
        resource: 'product',
        action: 'all',
        isMarketplace: true,
      },
      {
        title: 'Lazada',
        route: 'ProductsLazada',
        resource: 'product',
        action: 'all',
        isMarketplace: true,
      },
      {
        title: 'Shopee',
        route: 'ProductsShopee',
        resource: 'product',
        action: 'all',
        isMarketplace: true,
      },
    ],
  },
  {
    header: 'Laporan',
    resource: 'report',
    action: 'all',
  },
  {
    title: 'Kelola Laporan',
    icon: 'BarChart2Icon',
    resource: 'report',
    action: 'all',
    children: [
      {
        title: 'Laporan Penjualan',
        resource: 'report',
        action: 'all',
        route: 'ReportsOrder',
      },
      {
        title: 'Laporan Stok',
        resource: 'report',
        action: 'all',
        route: 'ReportsProduct',
      },
    ],
  },
  {
    header: 'Pengaturan',
    resource: 'settings',
    action: 'all',
  },
  {
    title: 'Langganan',
    icon: 'ShoppingBagIcon',
    resource: 'subscribe',
    action: 'all',
    children: [
      {
        title: 'Pilih Paket',
        route: 'subscription',
        resource: 'subscribe',
        action: 'all',
      },
      {
        title: 'Riwayat Langganan',
        route: 'subcriptionHistory',
        resource: 'subscribe',
        action: 'all',
      },
    ],
  },
  {
    title: 'Integrasi Toko',
    icon: 'LinkIcon',
    route: 'integration',
    resource: 'settings',
    action: 'all',
  },
];
