<template>
  <p class="clearfix mb-0">
    <span
      v-if="new Date().getFullYear() !== 2022"
      class="float-md-left d-block d-md-inline-block mt-25 noselect"
    >
      © Copyright kanaldagang.com <span>2022</span> - {{ year }}
    </span>
    <span
      v-else
      class="float-md-left d-block d-md-inline-block mt-25 noselect"
    >
      © Copyright kanaldagang.com <span
        class="noselect"
        @click="addCount()"
      >{{ year }}</span>
    </span>

    <span class="float-md-right d-none d-md-block noselect">
      Kanal Dagang is Product of
      <b-link href="https://www.kalapatec.id/">Kalapa Technology</b-link>
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BLink,
  },
  setup() {
    const year = new Date().getFullYear()
    const cCOunt = ref(0)

    const addCount = () => {
      if (cCOunt.value === 2) {
        console.log(
          '%cKanal Dagang',
          'color: #f00; font-weight: bold; font-size: 50px;background: yellow; padding: 5px;',
        )
        cCOunt.value = 0
      } else {
        cCOunt.value += 1
      }
    }

    return {
      year,
      addCount,
    }
  },
}
</script>

<style lang="scss" scoped>
  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently   supported by Chrome, Edge, Opera and Firefox */
}
</style>
